<template>
  <div>
    <activaitor :placeholder="placeholder" @search="search"></activaitor>
  </div>
</template>

<script>
import activaitor from "@/EK-core/components/EK-dialog/activaitor.vue";
import { mapState } from "vuex";
export default {
  components: {
    activaitor,
  },
  computed: {
    ...mapState({
      settingKeys: (state) => state.settings.settingKeys,
    }),
    placeholder(){
      let path =  this.$route.path;
      if(path.indexOf('about') != - 1 || path.indexOf('contact') != - 1){
        return ''
      }
      else return 'البحث في الاعدادات'
    }
  },
  methods: {
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: this.settingKeys,
        query,
      });
    },
  },
};
</script>

<style>
</style>